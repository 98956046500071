@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");


html {
  /* scrollbar-gutter: auto; */
  font-family: "Inter", sans-serif;
  background-color: #f9fafb;
  color: #111827;
}




 .bi {
  font-size: 20px;
 }

 .center {
  position: absolute;
  top: 50%;
  left:50%;
  transform: translate(-50%, -50%);
  width: 26rem;
  padding: 2rem;
 }

 input[type=checkbox] {
  transform: scale(1.2);
}


 #root .ql-container {
  font-size: 16px;
  border: none;
}

/* Snow Theme */
#root .ql-snow.ql-toolbar {
  display: block;
  background: white;
  width: 100%;
  border: none;
  position: sticky;
  top: 0px;
  z-index: 100;
}

#root .ql-editor {


}

#root ul {
  list-style: inside;
  padding-left: 2em;
}
#root ol {
  padding-left: 3em;
  list-style: decimal;
}

#root pre {
  color: white;
  background: #27272a;
  border-radius: 3px;
  padding: 2px 5px;
}

#root blockquote span {
  font-style: italic;
  color: gray !important;
}

#root blockquote {
  border-left: thick solid #818cf8;
  padding: 0 20px;
  font-style: italic;
  color: gray !important;
}

blockquote:before {
  content: open-quote;
  font-family: sans-serif;
  padding-right: 10px;
  font-size: 30px;
  color: #818cf8 !important;};

    
#root a {
  color: #3b82f6 !important;
  text-decoration: none;
}


  #navbar{
  position: fixed; /* Make it stick/fixed */
  top: 0; /* Stay on top */
  width: 100%; /* Full width */
  transition: top 0.3s; /* Transition effect when sliding down (and up) */
  }